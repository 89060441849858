<template>
  <div>
    <h3
      class="font-jost font-bold text-white text-2xl sm:text-3xl xl:text-[4.221rem] xl:leading-[6.099rem] uppercase text-center mt-8"
    >
      Keresel valamit?
    </h3>
    <div class="mt-8 max-w-[50rem] mx-auto px-4">
      <div
        class="flex flex-row items-center py-4 px-6 bg-mine-shaft-600 border border-mine-shaft-300 rounded-full relative"
      >
        <div class="flex flex-row gap-3 items-center w-full pr-24">
          <input
            v-model="searchValue"
            type="text"
            placeholder="Keresés..."
            class="font-jost bg-transparent text-white text-sm w-full"
            @keyup.enter="onEnterSearch"
          >
        </div>
        <div
          class="font-jost absolute px-8 py-2 text-white bg-ecstasy rounded-full text-sm right-[0.375rem] cursor-pointer transition hover:opacity-80"
          @click="searchNow"
        >
          Keresés
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

const searchValue = ref('')
const router = useRouter()

const searchNow = () => {
  if (searchValue.value.trim().length === 0) {
    return false
  }

  router.push({ path: '/kereses', query: { keyword: searchValue.value } })
}
const onEnterSearch = () => {
  router.push({ path: '/kereses', query: { keyword: searchValue.value } })
}
</script>
