import { defineStore } from 'pinia'

export const useLayoutStore = defineStore('layout', () => {
  const isDark = ref(false)

  const setTheme = (value: string) => {
    isDark.value = value !== 'light'
  }

  return {
    isDark,
    setTheme
  }
}, {
  persist: true
})
