<template>
  <nav
    class="flex items-center justify-start text-base"
  >
    <slot />
  </nav>
</template>

<style lang="scss" scoped>
.header-items {
    @screen lg {
        margin-top: -0.25rem;
    }
}
</style>
